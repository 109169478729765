import * as React from "react";
import { graphql, Link } from "gatsby";
import dayjs from "dayjs";

import Layout from "../../components/layout";
import SEO from "../../components/seo";

import banner from "../../../static/banner.png";

import "../../styles/blog.css";

const WORDS_PER_MINUTE = 265;

const Post = ({ data }) => {
  const { markdownRemark, authorsList } = data;
  const { frontmatter, wordCount, html } = markdownRemark;


  const author = authorsList.nodes.find(
    (el) => el.frontmatter.name === frontmatter.author
  );

  if (!author) return <></>


  const timeToRead = Math.ceil(wordCount.words / WORDS_PER_MINUTE);

  const image = frontmatter.featured_image
    ? frontmatter.featured_image
    : banner;

  const fixPath = (path) => {
    return path.toLowerCase().replace(" ", "-");
  };

  return (
    <>
      <SEO title={frontmatter.title} />
      <Layout>
        <main className="max-w-container flex flex-col space-y-10 py-10 lg:py-20 mx-auto blog lg:w-9/12">
          <header className="max-w-container flex flex-col bg-white items-start px-5 lg:px-60">
            <div className="text-red mb-2 text-sm">
              {frontmatter.tags &&
                frontmatter.tags.map((tag, i) => (
                  <span className="uppercase mr-4 ">{tag}</span>
                ))}
            </div>

            <h1 className="text-5xl text-dark-gray">{frontmatter.title}</h1>
            <div className="text-gray text-xl leading-7 py-6 border-b border-ecree">
              {frontmatter.description}
            </div>
            <Link
              to={`/blog/author/${fixPath(author.frontmatter.name)}`}
              className="no-underline"
            >
              <div className="flex items-center mt-4 mb-2">
                <div>
                  <img className="avatar" src={author.frontmatter.avatar} />
                </div>
                <div>
                  <div className="text-gray">{author.frontmatter.name}</div>
                  <div className="text-gray text-sm">
                    {dayjs(frontmatter.date).format("DD MMM YYYY")}
                    <span className="mx-2">•</span>
                    <span>{timeToRead} min read</span>
                  </div>
                </div>
              </div>
            </Link>
          </header>
          <div className="flex flex-col lg:px-28">
            <img className="featured-image" src={image} />
          </div>
          <div className="px-5 lg:px-60">
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </div>
        </main>
      </Layout>
    </>
  );
};

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        author
        tags
        date
        featured_image
        description
      }
      wordCount {
        words
      }
    }
    authorsList: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "content/(authors)/" } }
    ) {
      nodes {
        frontmatter {
          name
          avatar
        }
      }
    }
  }
`;

export default Post;
